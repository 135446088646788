.card {

	display: inline-block;
	width: 100%;

	padding: 36px;
	margin-bottom: 20px;

	&.card-small {
		padding: 18px;
	}

	> .card-title {
		font-family: @brand-secondary-font;
		font-size: 1.55rem;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 16px;
	}

	&.card-default {
		background-color: #fff;
		color: #000;
	}

	&.card-primary {

		color: #fff;
		background: #009fe3; /* Old browsers */
		background: -moz-linear-gradient(left, #009fe3 0%, #2d2e83 100%, #2d2e83 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, right top, color-stop(0%, #009fe3), color-stop(100%, #2d2e83), color-stop(100%, #2d2e83)); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(left, #009fe3 0%, #2d2e83 100%, #2d2e83 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(left, #009fe3 0%, #2d2e83 100%, #2d2e83 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(left, #009fe3 0%, #2d2e83 100%, #2d2e83 100%); /* IE10+ */
		background: linear-gradient(to right, #009fe3 0%, #2d2e83 100%, #2d2e83 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009fe3', endColorstr='#2d2e83', GradientType=1 ); /* IE6-9 */

		a {
			color: #fff;
		}

		.has-error .help-block {
			color: #fff;
		}

	}

	&.card-info {
		background: #2C2F84;
		color: #fff;
	}

	.help-block, .has-error label {
		color: #FFCFCF;
	}

}

@media only screen and (max-width: @screen-mobile) {

	.card {
		padding: 14px;

		h1 {
			text-align: center;
			font-size: 20px;
		}
	}

	.card > .card-title {
		font-size: 1.2rem;
	}

}
