/* HOME PROMO */
#promo {
	clear: both;
	float: left;
	width: 100%;
	background: #F0F0F0;
	padding: 40px 0;
	margin-bottom: 2.4rem;

	.row > div {
		padding: 0;
		position: relative;
		height: 397px;
		background: #fff;
	}

	.image {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: auto;
		}
	}

	.text {
		text-align: center;
		padding: 50px 30px !important;

		h3 {
			font-weight: 500;
			font-size: 307.14%;
			color: @brand-primary;
			margin: 0 0 30px 0;
		}

		small {
			font-size: 69.76%;
		}

		p {
			line-height: 1.5;
			&.link {
				clear: both;
				margin: 10px 0 0 0;
				width: 100%;
				text-align: center;
				position: absolute;
				bottom: 0;
				left: 0;
				text-transform: uppercase;
				a {
					color: @brand-primary;
					padding: 10px 0;
					display: inline-block;
					width: 100%;
					background: #DADADA;
				}
			}
		}
	}

	.grey {
		background: #DADADA !important;
		p {
			&.link {
				a {
					color: @brand-primary;
					background: #fff;
				}
			}
		}
	}
}

@media only screen and (max-width: @screen-sm-max) {

	#promo {
		padding: 20px 10px;
		border-bottom: solid 20px #f0f0f0;
	}

	#promo .row > div {
		height: auto;
	}

	#promo .image {
		position: static;
	}

}
