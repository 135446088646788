.faqs {

	.card-title {
		padding-bottom: 6px;
		margin-bottom: 30px !important;
		border-bottom: 1px solid @brand-info;
	}

	.faq-question {
		margin-bottom: 1rem;

		.prefix {
			color: darken(@brand-info, 35%);
		}
	}

	.faq-answer {
		margin-bottom: 2rem;
		background-color: #fff;
		padding: 20px 20px;
		color: #000;
	}

}
