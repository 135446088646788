#examples {
	clear: both;
	margin: 0 auto 30px auto;
	width: 960px;
	overflow: auto;

	h3 {
		font-weight: 500;
		color: @brand-primary;
		font-size: 307.14%;
	}

	h4 {
		font-size: 200%;
		margin: 0 0 15px 0;
	}

	small {
		font-size: 57.14%;
	}

	ul {
		margin: 0 0 20px 0;
		padding: 0;
		list-style: none;
		display: inline-block;

		li {
			display: inline-block;
			margin: 0 10px 0 0;
			font-size: 150%;
			line-height: 0.7;

			&.price {
				font-weight: 500;
				color: @brand-primary;
				font-size: 307.14%;
			}
		}
	}

}

@media only screen and (max-width: @screen-sm-max) {

	#examples {
		display: none;
	}

}
