.services {
	min-height: 435px;
	text-align: center;

	section {
		border-top: 2px solid #9CADCA;
		padding: 23px 0;
	}

	.card-title {
		font-size: 1.9em !important;
	}

	.title {
		color: @brand-primary;
		font-size: 1.9em;
		font-weight: 500;
		margin-bottom: 0.8rem;
	}

	a {
		color: #fff;
		text-decoration: none;
		position: relative;
		font-size: 1.3em;

		&:after {
			background: url(../images/buttons/white_arrow_right.png) no-repeat 0px 0px;
			float: left;
			content: "";
			position: absolute;
			right: -27px;
			top: -5px;
			width: 16px;
			height: 31px;
			z-index: 100;
		}
	}
}

@media only screen and (max-width: @screen-mobile) {

	.services {
		min-height: auto;
		.card-title, .title {
			font-size: 1.5em !important;
		}

		a {
			font-size: 1em;
		}

		section {
			padding-top: 18px;
		}
	}

}
