.booking-form label.passengers {
	font-size: 14px;
}

/* booking errors */
#booking-errors {
	// width: 100%;
	min-height: 10px;
	background: #f0f0f0;
	color: @brand-primary;
	position: relative;
	z-index: 101;
	margin-bottom: 0;

	&:before {
		position: absolute;
		color: #f0f0f0;
		font-size: 100px;
		bottom: -52px;
		left: 50%;
		margin: 0 0 0 -50px;
	}

	.error-title {
		font-family: Raleway;
		font-size: 284%;
		text-transform: none;
		text-align: center;
	}

	.error-title:only-child {
		margin-bottom: 0;
	}

	p {
		font-weight: 400;
		margin-bottom: 0.3rem;
	}
}

.booking-errors-container {
	display: none;
	width: 50%;
}

.booking-has-errors {
	.booking-errors-container {
		display: block;
	}
}

.booking-has-different-return {
	.booking-errors-container {
		width: 100%;
	}
}

// #booking-errors.booking-errors-right {
// 	position: absolute;
// 	height: 443px;
// 	margin-bottom: 0;
//
// 	&:before {
// 		left: -10px;
// 		top: 40%;
// 	}
// }

.booking-form {
	.form-group {
		margin-bottom: 18px;
	}

	.card-title {
		text-align: left;
		text-transform: none;
	}

	.btn-group {

		display: block;
		z-index: 4;
		// background-color: transparent;

		.btn {
			text-transform: none;
			text-align: left;
			// background-color: #fff;
			color: #fff;
			padding: 0px;
			padding-left: 25px;
			padding-right: 25px;
			font-size: 14px;
			height: auto;
			margin-bottom: 12px;
		}

		&.btn-transfer-types .btn:nth-child(2) {
			width: 44%;
		}

		&.btn-transfer-types .btn {
			font-size: 12px;
			padding-right: 22px;
		}

		.btn:before {
			font-family: 'icomoon';
			font-size: 11px;
			text-align: center;
			content: "\ee75";
			position: absolute;
			border: 2px solid #fff;
			border-radius: 50%;
			width: 20px;
			line-height: 17px;
		}

		.btn:before {
			left: -4px;
		}

		.btn.active:before {
			color: @brand-primary;
		}
	}

	label {
		font-size: 18px;
		padding: 0;
	}

	.collection-container {

		&.collection-empty {
			display: none;
		}

		text-align: center;

		.field {
			padding: 10px;
		}

		.time {
			font-size: 120%;
			margin-top: 0.5rem;
		}
	}

	.airport select option[disabled] {
		display: none;
	}

	.airport-notes {
		background: rgba(255, 255, 255, 0.8);
		color: #000;
		padding: 16px 8px;
		margin-bottom: 0.5rem;

		.moon-icons {
			position: relative;
			top: 2px;
			font-size: 17px;
		}
	}

	.booking-address li {
		background: rgba(0, 0, 0, 0.5);

		label {
			padding: 20px;
			width: 100%;
			cursor: pointer;
		}
	}

	.booking-address li.active {
		background: rgba(0, 0, 0, 0.8);
	}

	input#date {
		min-height: 50px;
		font-family: @brand-primary-font;
	}

	.field .field-prefix {
		float: left;
		padding: 16px;
		margin-top: -16px;
		margin-left: -14px;
		margin-right: 14px;
		width: 13%;
		background: rgba(0, 0, 0, 0.125);
	}

	.with-icon {
		position: relative;
		background-color: #fff;
	}

	.with-icon .form-control {
		position: relative;
		background-color: transparent;
		z-index: 1;
	}

	.with-icon .moon-icons {
		position: absolute;
		right: 10px;
		top: 30%;
		color: #777;
		font-size: 20px;
	}

	.timetable-loading .timetable-icon {
		color: #264093;
		z-index: 555;
		.spinner;
	}

	.timetable-loading .timetable-icon:before {
		content: "\eb22";
	}

	.timetable-loading .timetable-id {
		background-color: #e0e0e0;
	}
}

.postcode-loading .postcode-icon {
	z-index: 555;
	.spinner;
}

.postcode-loading .postcode-icon:before {
	content: "\eb22";
}

#confirm-details {
	label {
		font-size: 15px;
	}
}

#booking-form-flight {
	.col {
		width: 25%;
		padding-left:  ceil((@grid-gutter-width / 2));
		padding-right: floor((@grid-gutter-width / 2));
		float: left;
	}
}

.booking-form, div#top div.cols #booking-outcome {
	position: relative;
}

.booking-form label {
	font-weight: normal;
	float: none;
	width: auto;
}

.booking-form select.input-lg {
	line-height: 34px;
}

.booking-form .input-lg {
	font-size: 14px;
}

.booking-form input.error, .booking-form select.error {
	background: #fce8da !important;
}

.booking-form .price {
	font-size: 3em;
	margin-bottom: 0.6rem;
}

.booking-form .price-summary p {
	font-size: 112%;
}

.booking-form.quote-price-stage {
	h2 {
		margin-bottom: 0.4rem;
	}
}

.booking-steps {
	padding: 0;
	margin: 17px auto;
	display: inline-block;

	li {
		display: inline-block;
		width: 16px !important;
		height: 16px;
		background: #fff;
		text-align: left;
		margin: 0 3px;
	}

	li.complete {
		background: @brand-primary;
	}

	a, span {
		float: left;
		width: 100%;
		height: 100%;
		text-indent: -999999px;
	}
}

div#top div.cols div.col-long form.booking-form div.message {
	float: left;
	width: 100%;
	clear: both;
	margin: 0 0 20px 0;
}
div#top div.cols div.col-long form.booking-form div.message p {
	margin: 0 0 5px 0;
}
div#top div.cols div.col-long form.booking-form div.message p strong {
	font-size: 128.57%;
}

div#top div.cols div.col-long form.booking-form div.quote {
	float: left;
	width: 100%;
	clear: both;
	margin: 0 0 80px 0;
	text-align: center;
}

#booking-form-flight .journey-info {
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	.checkin-time {
		display: none;
	}
}

#booking-form-flight #journey-2 {
	border-bottom: none;
}

div#top div.cols div.col-long form.booking-form div.journey-info:last-child {
	margin: 0;
}

/* SUMMARY */
div.summary h3 {
	background: @brand-primary;
	color: #fff;
	text-align: center;
	font-size: 200%;
	padding: 15px 20px;
	height: 60px;
	position: relative;
	margin-bottom: 0;
}

div.summary h3 em {
	position: absolute;
	right: 20px;
	top: 9px;
	font-family: arial;
	font-style: normal;
	font-size: 145%;
	font-weight: 500;
	z-index: 2000;
}

div.summary h3:after {
	position: absolute;
	top: 0;
	right: 0;
	content: '';
	height: 100%;
	width: 222px;
	background: linear-gradient(109deg, @brand-primary 0%, @brand-primary 26%, @brand-secondary 0%);
}

#booking-summary {
	clear: both;
	position: relative;
	margin-bottom: 2rem;

	.summary-section {
		background: #F0F0F0;
		border-bottom: 3px solid #fff;
		padding: 40px 90px;
		position: relative;

		.row {
			margin: 0 0 20px 0;
		}

		.row:last-child {
			margin: 0;
		}

		h4 {
			font-size: 150%;
			color: @brand-primary;
			font-weight: 500;
			margin: 0 0 25px 0;
		}

		p {
			margin-bottom: 0.5rem;
		}

		p.edit {
			position: absolute;
			top: 43%;
			right: 3%;
			border: 1px solid #ccc;
		}

		p.edit a {
			padding: 10px;
			color: #706f6f;
			float: left;
			width: 100%;
			font-size: 24px;
			text-decoration: none;
		}
	}
}

/* BOOKING OUTCOME */
#booking-outcome {
	min-height: 10px;
}

#booking-outcome div.outcome {
	float: left;
	width: 100%;
	clear: both;
	margin: 0 0 20px 0;
	text-align: center;
}

#booking-outcome div.outcome p {
	float: left;
	width: 100%;
	clear: both;
}

#booking-outcome div.outcome h2 {
	margin: 0;
}

#booking-outcome div.outcome p.success {
	font-size: 128.57%;
	margin: 0 0 20px 0;
	text-transform: uppercase;
}

#booking-outcome div.outcome p.button {
	margin: 20px 0 0 0;
}

#booking-outcome div.outcome p.message {
	margin: 40px 0 20px 0;
}

#booking-outcome div.outcome p.button a.button-link {
	max-width: 340px;
	float: none;
	display: inline-block;
}

.booking-form .quote strong {
	font-size: 307.14%;
	font-weight: normal;
}

.booking-form {
	.direction-type {
		font-size: 23px;
	}
}

#confirm-details {
	margin-top: -2.1rem;
}

@media only screen and (max-width: @screen-md-max) {

	.booking-steps {
		li {
			width: 15px !important;
			height: 15px;
		}
	}

	.booking-nav {

		.btn-prev, .btn-next {
			width: 40px;
			padding-left: 0;
			padding-right: 0;
			font-size: 0;

			&:before {
				border: none;
			}

			&:after {
				background-color: transparent;
			}

			&:before, &:after {
				opacity: 1;
				background: @brand-secondary;
				font-size: 26px;
				text-align: center;
				padding-left: 0;
				padding-right: 0;
			}
		}

		.btn-next {
			float: right;
		}
	}

	#booking-summary {
		.summary-section {
			padding: 20px 0px;

			h4 {
				margin-left: 15px;
			}
		}
	}

	div.summary h3 {
		padding: 13px 18px;
		height: 55px;
		font-size: 136%;
		text-align: left;

		&:after {
			width: 95px;
		}

		em {
			font-size: 89%;
			right: 4px;
			top: 15px;
		}
	}

	/** Quote Price Stage */
	.quote-price-stage {
		.text-summary {
			text-align: center;
			margin-bottom: 1rem;
		}
	}

	.booking-errors-container {
		width: 100%;
	}

	#booking-errors {
		padding: 16px;

		.error-title {
			font-size: 180%;
		}
	}

	#booking-errors ~ .card {
		padding-top: 30px;
	}

	.booking-form .btn-group {
		display: block;
		height: 52px;
	}

}

#booking {
	position: relative;
}

.reset-booking {
	position: absolute;
	z-index: 1;
	color: #dedede;
	font-size: 16px;
	right: 0;
	padding: 6px;
	background: rgba(0, 0, 0, 0.35);
}

table.bookings-list {

	td {
		padding: 14px 0;
	}

	.btn {
		padding: 6px 8px;
		font-size: 100%;
	}

}

@media only screen and (min-width: @screen-xs-min) and (max-width: 780px) {

	.booking-form {

		.btn-group {

			&.btn-locations .btn {
				width: 50% !important;
				margin-bottom: 0;
			}
		}

	}

}

@media only screen and (max-width: @screen-sm-max) {

	#booking-form-flight {
		.col {
			width: 100%;
			float: none;
		}
	}

	table.booking-summary {
		colgroup col {
			width: 120px;
		}
	}

	.booking-form .btn-group.btn-transfer-types .btn {
		padding-left: 29px;
		padding-right: 27px;
		width: auto !important;
	}

	.booking-form {

		label.passengers {
			font-size: 100%;
		}

		&.quote-price-stage {
			.field {
				max-height: 47px;
				overflow: hidden;
			}

			.field .field-prefix {
				width: 22%;
			}
		}

		.moon-icon-clock {
			display: none;
		}
	}

	#booking .card {
		margin-bottom: 0;
	}

	#booking-errors.booking-errors-right {
		position: static;
		height: auto;
	}

	div#top {
		margin-bottom: 0;
	}

}
