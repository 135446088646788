ul.pagination {
	display: inline-block;
	list-style: none;
	margin-bottom: 1.5rem;
	padding-left: 0;

	> li {
		float: left;
		margin-right: 5px;
		
		span, a {
			display: block;
			width: 22px;
			height: 22px;
			background-color: #ddd;
			color: #ddd;
		}
	}

	> li.active {
		span, a {
			background-color: @brand-primary;
			color: @brand-primary;
		}
	}

	> li a[rel], > li.disabled {
		display: none;
	}

}
