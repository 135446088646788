.question-range {
	.question-options {
		position: relative;
	}
}

.question-radio {
	label {
		margin-right: 1rem;
	}
}

.question-title {
	margin-bottom: 0.8rem;
}
