.main-navigation {
	clear: both;
	float: left;
	width: 100%;
	background: #fff;
	position: relative;
	z-index: 50;
	margin-bottom: 1.6rem;
	font-family: @brand-secondary-font;

	p {
		position: absolute;
		right: 0;
		top: 0;
		padding: 10px;
		cursor: pointer;
		display: none;
	}

	ul {
		float: left;
		width: 100%;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	li, li a {
		float: left;
	}

	li {
		width: 25%;
		position: relative;
	}

	li:last-child {
		margin-right: 0;
	}

	li a {
		font-size: 128.57%;
		text-decoration: none;
		color: #111;
		text-transform: uppercase;
		padding: 15px 0;
		float: left;
		width: 100%;
		text-align: center;
	}

	li a:hover {
		color: @brand-primary;
	}


	li div {
		background: none;
		display: block;
		margin: 0 0 0 -99999px;
		overflow: hidden;
		position: absolute;
		top: 100%;
		transition: opacity 150ms ease 250ms;
		white-space: nowrap;
		width: 270px;
	}


	li div ul {
		background: none;
	}

	li div ul li {
		float: left;
		width: 100%;
		margin: 0;
		text-align: left;
		border-top: solid 1px @brand-primary;
	}

	li div ul li a {
		float: left;
		width: 100%;
		text-align: left;
		padding: 15px 40px;
		background: #fff;
		text-transform: none;
	}

	li div ul li:hover a {
		background: #F39200;
		color: #fff;
	}

	li:hover div {
		margin-left: 0;
	}
}

@media only screen and (max-width: @screen-xs-max) {

	.main-navigation {
		position: absolute;
		right: 0;
		top: 47px;
		height: 100%;
		display: none;

		p {
			z-index: 5000;
			display: block;
		}

		ul {
			background-color: #fff;
		}

		ul li {
			float: left;
			width: 100%;
			margin: 0;
			border-top: 1px solid @brand-primary;
			div {
				margin: 0;
				position: relative;
				width: 100%;
			}
		}

		div.col ul li:first-child {
			border-top: none;
		}

		li a.parent {
			display: none;
		}

		li div ul li a {
			text-align: center;
			text-transform: uppercase;
		}
	}

}
