*, *:before, *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
/* HTML ELEMENTS */
html, body {
	height: 100%;
}
body {
	background: #fff;
	font-family: @brand-primary-font;
	font-weight: 300;
	font-style: normal;
	font-size: 14px;
	line-height: 1.1;
	color: #111;
	position: relative;
	-webkit-font-smoothing: antialiased;
}

ol.list-primary {
	padding-left: 0;

	> li {
		list-style-type: none;
		margin-bottom: 1rem;
		counter-increment: step-counter;

		&:before {
			content: counters(step-counter, ".", decimal-leading-zero);
			color: @brand-primary;
			margin-right: 14px;
		}
	}
}

/* HTML LINKS */
img {
	border: none;
}

.news-letter-form {
	margin-bottom: 2rem;

	input {
		border: solid 1px #777;
	}

	label {
		position: absolute;
		left: -999999px;
	}
}
