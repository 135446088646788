div.contact form#contact-form textarea {
	height: 105px;
}

div.contact form#contact-form p {
	margin: 0;
}

div.contact p.terms {
	text-align: center;
	font-size: 85.71%;
}

/* CONTACT PAGE */
#map {
	background: rgba(255,255,255,0.6);
	width: 100%;
	height: 435px;
	margin: 0 0 30px 0;
}
