/* top elements */
div#top {
	position: relative;
	padding: 18px 0 0 0;
	margin: 0 auto;
	margin-bottom: 38px;
	width: 100%;
	max-width: 1200px;
	min-height: 708px;
}
div#top div.slider {
	width: 100%;
	height: 708px;
	position: absolute;
	left: 0;
	top: 0;
	background: #ccc;
}
div#top div.slider div.slide {
	position: absolute;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: cover;
}
div#top div.slider div.slider2 {
	height: 100%;
}
div#top div.slider.with-slider div.slide {
	position: relative;
}
div#top div.slider div.slide div.container {
	padding: 283px 522px 0 0;
}
div#top div.slider div.slide div.overlay {
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: 0 0;
}
div#top div.slider div.slide div.overlay.orange {
	background-image:url(../../images/overlays/orange.png);
}
div#top div.slider div.slide div.overlay.blue {
	background-image:url(../../images/overlays/blue.png);
}
div#top div.slider div.slide p, div#top div.slider div.slide p a {
	color: #fff;
}
div#top div.slider div.slide p.text {
	font-size: 307.14%;
	margin: 0 0 15px 0;
}
div#top div.slider div.slide p.link {
	padding: 10px 0;
}
div#top div.slider div.slide p.link a {
	font-size: 128.57%;
	text-decoration: none;
	position: relative;
}
div#top div.slider div.slide p.link a:after {
	background: url(../images/buttons/white_arrow_right.png) no-repeat 0px 0px;
	float: left;
	content: "";
	position: absolute;
	right: -27px;
	top: -5px;
	width: 16px;
	height: 31px;
	z-index: 100;
}
/* SLIDER NAVIGATION */
div#top div.slider.with-slider ul.slick-dots {
	margin: 0;
	padding: 0;
	list-style: none;
	position: absolute;
	right: 120px;
	top: 491px;
}

div#top div.slider.with-slider ul.slick-dots li {
	display: inline-block;
	width: 15px;
	height: 15px;
	background: #fff;
	margin: 0 0 0 5px;
}
div#top div.slider.with-slider ul.slick-dots li.slick-active {
	background: @brand-primary;
}
div#top div.slider.with-slider ul.slick-dots li button {
	opacity: 0;
	border: none;
	width: 100%;
	height: 100%;
	border: none;
	cursor: pointer;
}

.slider .slide-title {
	text-transform: uppercase;
    text-align: right;
    color: #f99b5a;
    margin-left: 50px;
    font-size: 260%;
    text-shadow: 1px 1px 1px #136ea9;
    /* letter-spacing: 2px; */
    font-weight: 400;
    line-height: 1.1;
    padding-top: 19px;
	position: absolute;
	top: 214px;
	right: 120px;
}
.slider .slide-title strong {
	// font-weight: bold;
    color: #fff;
}

/* HEADER */
div#top header {
	margin: 0 auto;
	position: relative;
	z-index: 5;
	height: 146px;
	margin-bottom: 38px;
}

div#top header.with-text {
	margin-bottom: 349px;
}
div#top header p.menu {
	float: left;
	position: absolute;
	padding: 8px;
	left: 0;
	color: #fff;
	font-size: 35px;
	cursor: pointer;
	display: none;
	z-index: 1000;
}
div#top header div.logo {
	z-index: 50;
	margin-bottom: 0.7rem;
}
div#top header .logo {
	float: left;
	height: 60px;
	margin: 0 0 10px 0;
	width: 100%;
}
div#top header .logo img {
	width: auto;
	height: 100%;
}
div#top header div.logo ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.headline-home {
	text-transform: uppercase;
    text-align: right;
    color: #f99b5a;
    margin-left: 50px;
    font-size: 260%;
    text-shadow: 1px 1px 1px #136ea9;
    /* letter-spacing: 2px; */
    font-weight: 400;
    line-height: 1.1;
    padding-top: 19px;
}
.headline-home strong {
	// font-weight: bold;
    color: #fff;
}

.nav-locations {

	padding-left: 0;
	margin: 0;

	li {
		color: #fff;
		display: inline-block;
		padding: 0 0 0 8px;
		margin: 0 8px 0 0;
		border-left: solid 1px #fff;
		text-transform: uppercase;
	}

	li:first-child {
		padding: 0;
		border: none;
	}
}

div#top header div.right {
	padding: 15px 0 0 0;
	float: right;
	width: 100%;
	text-align: right;
	position: relative;
}

header .language {
	font-size: 85.71%;
	margin-top: 18px;
	margin-bottom: 38px;
}
header .language a {
	text-decoration: none;
	display: inline-block;
	color: #111;
	padding: 1px 31px 1px 0;
	background-repeat: no-repeat;
	background-position: 100% 0px;
}
header .language a.uk {
	background-image: url(../images/flags/uk.png);
}

header .strap {
	font-weight: 500;
	font-size: 114.28%;
	text-transform: uppercase;
	color: #fff;
	margin-bottom: 24px;
	line-height: 25px;
}

div#top div.cols {
	/*position: relative;
	z-index: 10;*/
	float: left;
	width: 100%;
	margin: 0 0 20px 0;
}
div#top div.cols div.col {
	float: left;
	width: 475px;
	min-height: 475px;
	padding: 25px;
	color: #fff;
}
div#top div.cols div.col-long {
	width: 100%;
	min-height: 450px;
}

div#top div.cols div#blue-panel {
	min-height: 10px;
}

div#top div.cols div.col1 {
	margin-right: 10px;
}
div#top div.cols div.col1 .hide {
	position: absolute;
	left: -9999999px;
}
div#top div.cols div.col2 {
	position: relative;
}

header .phone {
	color: #fff;
	font-size: 160%;
}

.top-container {
	position: relative;
	max-width: 960px;
	margin: 0 auto;
}

.top-title {
	color: #fff;
	font-size: 2.2rem;
	margin-bottom: 0.8rem;
	text-transform: uppercase;
}

.top-content {
	background-color: #f0f0f0;
	padding: 50px;
}

.top-content .gutterless {
	margin-left: -50px;
	margin-right: -50px;
	margin-top: -50px;
}

.top-container > .card {
	margin: 0;
	position: relative;
}

/* CONTENT AREA */
#content {
	position: relative;
	clear: both;
	width: 960px;
	margin: 0 auto;

	h1 {
		color: #fff;
		text-transform: uppercase;
		font-size: 307.14%;
		margin: 0 0 30px 0;
	}

	div.text {
		clear: both;
		background: #fff;
		padding: 30px;
		min-height: 450px;
	}

	div.text-clean {
		padding: 0 30px;
	}

	div.text p {
		margin: 0 0 20px 0;
	}
}

/* FOOTER */

footer {
	text-align: center;
	ul, p {
		clear: both;

	}
	ul {
		padding: 0;
		li {
			display: inline-block;
			margin: 0;
		}
	}

	ul.logos {
		li {
			padding: 0 30px;
		}
	}
	ul.buttons {
		li {
			a.btn {
				padding-left: 10px;
				text-align: left;
				font-family: @brand-secondary-font;
			}
			&.first, &.fourth {
				width: 190px;
			}
			&.second, &.third {
				width: 255px;
				padding: 0 0 0 5px;
			}
			&.fourth {
				padding: 0 0 0 5px;
			}
		}
	}

	p.terms a {
		color: #111;
		text-decoration: none;
		font-size: 85.71%;
		margin: 0;
	}
	ul.social {
		margin: 0 0 30px 0;
		li {
			display: inline-block;
			width: 32px;
			background: #878787;
			margin: 0 8px 0 0;
			a {
				display: inline-block;
				width: 100%;
				height: 32px;
				background: #878787;
				color: #fff;
				span.moon-icons {
					float: left;
					width: 100%;
					height: 100%;
					text-align: center;
					vertical-align: middle;
					font-size: 22px;
					padding: 5px 0;
					&:before {
						text-indent: 0;
					}
				}
			}
		}
		li:last-child {
			margin: 0;
		}
	}
}

@media only screen and (max-width: @screen-xs-max) {
	/* TOP */
	div#top {
		padding: 0;
		min-height: auto;
	}
	div#top div.slider {
		display: none;
	}
	div#top div.slider div.slide {
		display: none;
	}

	div#top header p.menu {
		display: block;
		position: absolute;
		right: 0;
		left: auto;
	}
	div#top header {
		width: 100%;
		margin: 0;
		background-color: #ef8439;
		height: 60px;
	}
	div#top header .logo {
		margin-top: 13px;
		margin-bottom: 0px !important;
		margin-left: 6px;
		height: 32px;
	}
	header .phone {
		font-size: 88%;
		opacity: 0.60;
		margin-top: 13px;
		display: inline-block;
	}
	header .language {
		display: none;
	}

	div#top.active {
		height: 100%;
	}
	div#top.active header {
		height: 100%;
		z-index: 2000;
	}
	div#top.active header div.right {
		height: 100%;
		z-index: 99999;
	}
	div#top.active .main-navigation {
		display: block;
		z-index: 1000;
	}
	div#top.active .main-navigation ul li div.show {
		display: block;
	}

	header .strap {
		margin-top: 10px;
		margin-bottom: 12px;
		text-align: center;
		color: #fff;
		font-size: 100%;
		line-height: 6px;
	}

	header .brand-motto-container {
		float: none !important;
	}

	/* quote box */
	div#top div.cols {
		margin-bottom: 10px;
		padding: 0 10px;
	}
	div#top div.cols div.col {
		width: 100%;
		min-height: 10px;
		padding: 20px 10px;
		margin: 0 0 10px 0;
	}
	div#top div.cols div.col h1, div#top div.cols div.col h2 {
		font-size: 178.57%;
	}
	div#top div.cols div.col form ul li.four {
		overflow: hidden;
	}
	div#top div.cols div.col form ul li.four label {
		font-size: 85.71%;
	}
	div#top div.cols div.col-long form.booking-form ul li.quarter {
		padding: 0;
		width: 100%;
	}
	div#top div.cols div.col2 dl dt {
		font-size: 135.71%;
	}
	div#top div.cols div.col2 dl dd {
		font-size: 92.85%;
	}

	.top-content {
		padding: 15px;
	}

	.top-title {
		text-align: center;
		font-size: 20px;
		background-color: #ef8439;
		margin-bottom: 0;
		padding-bottom: 0.3rem;
	}

	footer ul.social {
		padding-left: 0;

		li {
			width: 40px;
			margin-right: 12px;
		}
	}

	footer ul.buttons {
		padding-left: 20px;
		padding-right: 20px;

		li {
			width: 100% !important;
			padding: 0 !important;
			margin-bottom: 20px;
		}
	}

}
