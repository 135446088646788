.ui-datepicker {
	border: solid 1px #706f6f;
	background: #fff;
	z-index: 500 !important;
}
.ui-datepicker .ui-icon {
	color: @brand-secondary;
}
.ui-datepicker .ui-datepicker-header {
	padding: 10px 5px;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev, .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
	position: relative;
	float: left;
	width: 25px;
	height: 25px;
	padding: 5px;
	cursor: pointer;
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-indent: -99999px;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before, .ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
	position: absolute;
	left: 5px;
	top: 5px;
	content: "\edc3";
	font-size: 15px;
	color: @brand-secondary;
	text-indent: 0;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
	content: "\edbb";
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
	float: left;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-title {
	text-align: center;
	text-transform: uppercase;
	color: #706f6f;
	font-size: 128.57%;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-next {
	float: right;
}
.ui-datepicker .ui-datepicker-calendar {
	border: none;
	border-collapse: collapse;
	width: 100%;
}
.ui-datepicker .ui-datepicker-calendar th span, .ui-datepicker .ui-datepicker-calendar td span, .ui-datepicker .ui-datepicker-calendar td a {
	display: inline-block;
	padding: 14px;
}
.ui-datepicker .ui-datepicker-calendar th {
	font-weight: 600;
	font-size: 85.71%;
	color: #706f6f;
}
.ui-datepicker .ui-datepicker-calendar td {
	text-align: center;
}
.ui-datepicker .ui-datepicker-calendar td:hover a {
	background: @brand-primary;
	color: #fff;
}
.ui-datepicker .ui-datepicker-calendar td, .ui-datepicker .ui-datepicker-calendar td a {
	color: #706f6f;
	text-decoration: none;
}
.ui-datepicker .ui-datepicker-calendar td.ui-datepicker-unselectable {
	background: #ececec;
	color: #cac9c9;
}
.ui-datepicker .ui-datepicker-other-month {
	background: #fff !important;
}
.ui-datepicker .ui-datepicker-calendar td.ui-datepicker-other-month {
	color: #e6e6e6;
}

/* PROMPTER */
.ui-autocomplete {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	float: left;
	display: none;
	min-width: 160px;
	padding: 0;
	margin: 0 0 10px 25px;
	list-style: none;
	background-color: #ffffff;
	border: 1px solid #ccc;
	border-top: none;
	-webkit-border-bottom-right-radius: 4px;
	-webkit-border-bottom-left-radius: 4px;
	-moz-border-radius-bottomright: 4px;
	-moz-border-radius-bottomleft: 4px;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}

.ui-autocomplete li {
	width: 100% !important;
	padding: 6px !important;
	color: #666;
	border-top: 1px solid #f5f5f5;
	margin: 0 !important;
	cursor: pointer;
	font-size: 92.30%;
	background-color: #fff;
}
.ui-autocomplete li:hover, .ui-autocomplete .ui-state-active {
	background: #fbfbfb;
}

.ui-autocomplete .ui-state-focus {
	background-color: lighten(@brand-primary, 5%) !important;
	color: #fff;
}

/* jquery changes */
div#accordion, div.accordion {
	clear: both;
}

.ui-accordion-header {
	cursor: pointer;
}

.ui-datepicker-buttonpane {
	button {
		background-color: lighten(@brand-primary, 5%);
		color: #fff;
		padding: 12px 20px;
		border: 0px solid #ccc;
	}

	button:last-child {
		float: right;
	}
}

.ui-datepicker .ui-datepicker-calendar {
	margin-bottom: 8px;
}

.ui-datepicker-calendar .ui-state-active {
	background-color: @brand-primary;
	color: #fff !important;
}

.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_time_label {
	padding-left: 0;
	padding-top: 15px;
	text-transform: uppercase;
	font-size: 17px;
	color: #706f6f;
}

.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_minute::before, .ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_second::before {
	font-weight: bold;
}

.ui-timepicker-select {
	border: 1px solid #dadada;
	margin-bottom: 8px;
}

.ui-timepicker-div .ui-datepicker-title {
	display: none;
}

.ui-timepicker-div .ui_tpicker_time_label {
	display: none;
}

.ui-datepicker-buttonpane .ui-datepicker-current {
	display: none;
}

.ui-timepicker-oneLine {
	width: 225px;
	margin: 0 auto;
}

.ui_tpicker_hour_slider {
	padding-right: 8px;
}

.ui_tpicker_minute_slider {
	padding-left: 8px;
}

.ui-helper-hidden-accessible {
	display: none;
}

@media only screen and (max-width: @screen-mobile) {

	.ui-autocomplete {
		overflow: auto;
	}

	.ui-autocomplete li {
		padding: 15px !important;
	}

}
