@brand-primary: #EC6608;
@brand-secondary: #E84E0F;

// FONTS
@brand-primary-font: Arial, Helvetica, sans-serif;
@brand-secondary-font: 'Raleway', Arial, Helvetica, sans-serif;

// @screen-xs-min: 336px;
// @screen-sm-min: 544px;
// @screen-md-min: 752px;
// @screen-lg-min: 960px;
@screen-mobile: 480px;

@container-large-desktop: 990px;


// Forms
@input-height-large: 50px;
@input-border-radius: 0;
@input-border-radius-large: 0;
@input-border-focus: #ccc;
