/* HTML TABLES */
table {
	margin: 0 0 20px 0;
	padding: 0;
	border: none;
	border-collapse: collapse;
	width: 100%;
}
.table th, .table td {
	padding: 4px 0;
}
table th {
	padding-bottom: 10px;
	text-align: left;
	font-weight: normal;
}
table th strong {
	font-weight: 600;
}

.table {
	
	width: 100%;

	th {
		font-weight: bold;
	}

	&.table-default {

	}
}
