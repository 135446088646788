body, h1, h2, h3, h4, p, dl, dl dt, dl dd {
	margin: 0;
	padding: 0;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 300;
	margin-bottom: 1.5rem;
	font-family: @brand-secondary-font;
}
p {
	margin-bottom: 20px;
}
a {
	text-decoration: none;
	color: @brand-secondary;
}

.primary-font {
	font-family: @brand-primary-font !important;
}
