div#timetable {
	padding: 0;
	background-color: #f0f0f0;
}

div#timetable h2 {
	padding: 20px;
	text-align: center;
	background: #ec6608;
	color: #fff;
	font-size: 200%;
	margin: 0 0 30px 0;
	text-transform: uppercase;
}

div#timetable h3 {
	margin: 0 0 5px 30px;
	color: #ec6608;
	font-size: 150%;
}

div#timetable .wrap {
	padding: 0 30px;
}
