.btn {
	cursor: pointer;
	border: none;
	font-size: 128.57%;
	font-weight: 300;
	padding: 15px 10px;
	text-align: center;
	text-decoration: none;
	position: relative;
	line-height: 18px;
	height: 50px;
	text-transform: uppercase;
}

.btn-primary {
	background-color: @brand-secondary;
	color: #fff;
	font-family: @brand-secondary-font;
	transition: all 0.2s ease-in-out;
}

.btn-link {
	background-color: transparent;
}

// .btn-primary.btn-next:hover {
// 	background-color: lighten(@brand-secondary, 5%);
// }

// .btn-primary.btn-next:hover:after {
// 	background: linear-gradient(109deg, lighten(@brand-secondary, 5%) 0%, lighten(@brand-secondary, 5%) 26%, lighten(@brand-primary, 5%) 0%);
// }

.btn-sm {
	padding: 7px 2px;
	height: 32px;
	font-size: 112%;
}

.btn:disabled {
	opacity: 0.7;
}

/*
.btn-primary:hover {
	background-color: darken(@brand-primary, 8%);
	transition: all 0.2s ease-in-out;
}
*/

.btn-prev:before, .btn-next:after {
	font-family: 'icomoon';
	font-size: 150%;
	position: absolute;
	width: 48px;
	height: 100%;
	opacity: 0.9;
	padding-top: 16px;
}

.btn-next {
	padding-right: 51px;
	transition: none;
}

.btn-prev {
	padding-left: 51px;
	transition: none;
}

.btn-primary.btn-next:before {
	content: '';
	height: 100%;
	position: absolute;
	top: 0;
	right: 31px;
	height: 100%;
}

.btn-primary.btn-next:after {
	right: 0;
	content: "\edbb";
	top: 0;
	padding-left: 15px;
	background: linear-gradient(109deg, @brand-secondary 0%, @brand-secondary 26%, @brand-primary 0%);
}

.btn-primary.btn-prev:before {
	top: 0;
	left: 0;
	text-align: left;
	padding-left: 8px;
	content: "\edc3";
	background: linear-gradient(-72deg, @brand-secondary 0%, @brand-secondary 26%, @brand-primary 0%);
}

.btn-block {
	display: block;
	width: 100%;
}
