.m-t-1 {
	margin-top: 1rem !important;
}

.m-t-2 {
	margin-top: 1.5rem !important;
}

.m-t-3 {
	margin-top: 2rem !important;
}

.m-b-0 {
	margin-bottom: 0 !important;
}

.m-b-1 {
	margin-bottom: 1rem !important;
}

.m-b-2 {
	margin-bottom: 1.5rem !important;
}

.m-b-3 {
	margin-bottom: 2rem !important;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-primary {
	color: @brand-primary;
}

.primary-bar {
	background-color: @brand-primary;
	text-align: center;
}

.primary-bar {
	background: @brand-primary;
	color: #fff;
	text-align: center;
	font-size: 200%;
	padding: 15px 20px;
	font-family: @brand-secondary-font;
}

/* CUSTOM CLASSES */
.hidden {
	position: absolute;
	left: -9999999px;
}
.clear {
	clear: both;
}

.page-break {
	page-break-after: always;
}
