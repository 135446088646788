.news {
	margin-bottom: 1.5rem;

	h2 {
		color: @brand-primary;
		font-size: 42px;
		font-weight: 400;
	}

	.news-date {
		color: @brand-primary;
		text-transform: uppercase;
	}

	.card {
		height: 360px;
		margin-bottom: 0;
	}

	.news-content, .news-filter {
		font-weight: 400;
		line-height: 1.4;
	}

	.news-content {
		position: relative;
	}

	.news-content:after {
		position: absolute;
		top: 40%;
		right: -40px;
		z-index: 1;
		font-family: 'icomoon';
		content: '\edc5';
		font-size: 66px;
		color: #fff;
	}

	.news-image {
		.background-image {
			width: 100%;
			background-size: cover;
			background-position: center;
		}
	}

	.news-item:nth-child(odd) {
		.news-content {
			float: right;
			width: 66.669%; // 66.66666667% = bootstrap but some reason leaves a bit of a gap.

			.card {
				background-color: #DADADA;
			}

			&:after {
				content: '\edc7';
				right: auto;
				left: -40px;
				color: #DADADA;
			}
		}

		.news-image {
			float: left;
			width: 33.33333333%;
		}
	}
}

.news-pagination {
	margin: 0 auto;
}

.news-filter {
	position: relative;

	&:hover {
		ul {
			max-height: 300px;
		}
	}

	.news-filter-bar {
		background-color: #dedede;
		padding: 12px 15px;
		text-align: right;

		cursor: pointer;

		&:hover {
			background-color: lighten(#dedede, 3%);
		}

		.clear-selection {
			float: left;
			color: #808080;
			margin-top: 3px;
		}

		.moon-icon-more {
			color: @brand-primary;
			font-size: 18px;
		}
	}

	.news-filter-title {
		font-size: 16px;
		text-transform: uppercase;
		margin-right: 8px;
	}

	ul {
		transition: max-height 500ms ease-in-out;
		position: absolute;
		max-height: 0;
		overflow: hidden;
		z-index: 1;
		list-style-type: none;
		background-color: #fff;
		padding: 0;
		margin: 0;
		font-size: 16px;
		width: 100%;
		li {
			display: block;
			border-bottom: 2px solid @brand-primary;
			padding: 8px 15px;

			label {
				font-weight: normal;
				width: 100%;
			}

			input {
				margin-right: 8px;
			}
		}

		li:last-child {
			border-bottom: none;
		}
	}

	label {
		float: none;
		padding: 0;
		width: auto;
	}
}

@media only screen and (max-width: @screen-xs-max) {

	.news .news-item:nth-child(odd) .news-content,
	.news .news-item:nth-child(odd) .news-image {
		float: none;
		width: 100%;
	}

}
