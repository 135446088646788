#payment {
	padding: 30px 0;
	clear: both;
	float: left;
	width: 100%;
	background: #f0f0f0;
	text-align: center;
}

#payment ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

#payment ul li {
	display: inline-block;
	margin: 0 5px;
}
