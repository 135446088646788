input[type=text], input[type=password], select, textarea {
	background: #fff;
	border: none;
	padding: 15px;
	font-family: @brand-primary-font;
	font-size: 100%;
	width: 100%;
	color: #363c47;
	height: auto;
	min-height: 34px;
}

form ul {
	margin: 0;
	padding: 0;
	list-style: none;
	float: left;
	width: 100%;
}

form ul li, form p {
	float: left;
	width: 100%;
	margin: 0 0 10px 0;
}

form ul li ul li {
	margin: 0;
}

form ul li.quarter {
	width: 25%;
	padding: 0 20px 0 0;
}

form ul li.quarter:nth-of-type(4) {
	padding: 0;
}

form ul li.half {
	width: 50%;
	padding: 0 20px 0 0;
}

form ul li.half:nth-of-type(2), form ul li.half:nth-of-type(2n) {
	padding: 0;
}

form ul li label small {
	font-size: 85.71%;
}

/* FORM ELEMENTS */
label, span.label {
	float: left;
	width: 100%;
	padding: 0 0 8px 0;
}

label.unstyled {
	float: none;
	width: auto;
	padding: 0;
}

.form-group {
	margin-bottom: 12px;
}

.form-control {
	border-width: 0px;
}

form p.prompt {
	text-align: center;
	margin: 0 0 30px 0;
}

.styled-radios {
	input {
		display: none;
	}

	label {
		cursor: pointer;
		z-index: 1;
	}

	label:before {
		font-family: 'icomoon';
		content: "\ee75";
		font-size: 20px;
	}

	input:checked + label:before {
		font-family: 'icomoon';
		content: "\ee6e";
		font-size: 20px;
	}

	.line {
		position: absolute;
		height: 5px;
		top: 8px;
		width: 89%;
		z-index: 0;
		background-color: #fff;
	}
}

.field {
	background-color: #fff;
	color: #000;
	padding: 16px 14px;
}

.has-error .help-block {
	border: 2px solid #ff5722;
	padding: 10px 15px;
	color: #ff5722;
}

.has-error, .has-success {
	.form-control {
		// border-width: 2px;
	}
}

.help-block {
	position: relative;
	margin-top: 12px;
}

.has-error .help-block:before {
	font-family: 'icomoon';
	content: "\edc4";
	position: absolute;
	top: -0.70em;
	font-size: 2.1em;
	color: #ff5722;
}

.card-primary .has-success {
	label {
		color: #fff;
	}
}

select.select-plain {
	-webkit-appearance: none;
	-moz-appearance: none;
}

.btn-group.btn-group-primary {
	label.btn {
		margin-right: 16px;
	}

	label.btn {
		border-left: 25px solid #ccc !important;
	}

	label.btn:hover {
		background-color: #efefef;
		color: #333;
	}

	label.btn.active {
		background-color: #1e53a2;
		border-color: #1768b9 !important;
		color: #fff;

		&:before {
			font-family: 'icomoon';
			content: "\ed6c";
			position: absolute;
			left: -19px;
			top: 17px;
			color: #fff;
			font-size: 12px;
		}
	}
}

@media only screen and (max-width: @screen-xs-max) {
	.btn-group.btn-group-primary {
		label.btn {
			width: 100%;
		}
	}
}
