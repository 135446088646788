.row.no-gutter {
	margin-left: 0;
	margin-right: 0;
}
.row.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

.row.row-small-gutter {
	margin-left: -8px;
	margin-right: -8px;
}

.row.row-small-gutter [class*='col-'] {
	padding-right: 8px;
	padding-left: 8px;
}



@media only screen and (min-width: @screen-mobile) {

	.table-container {
		display: table;
		width: 100%;
		height: 100%;
	}

	.table-row {
		display: table-row;
	}

	.table-row > [class*='col-'] {
		display: table-cell;
		float: none;
		vertical-align: top;
	}

	.table-row > [class*='col-'].table-middle {
		vertical-align: middle;
	}

}
